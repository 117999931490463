<template>
  <module
    ref="module"
    id="mat-relocation"
    titleIcon="fa fa-retweet"
    :title="$t('matrelocation')"
    :filters.sync="filters"
    api-url="ACCAPIURL_V4"
    url="/changelog/relocate"
  >
    <template slot="table-columns">
      <el-table-column prop="account" :label="$t('account')">
        <template slot-scope="{row}">
          <img class="mr-2"
               :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${row.logo}.png`"
               style="width: 38px;"
          >
          <span>{{ row.account }} ({{ row.platform.toUpperCase() }})</span>
        </template>
      </el-table-column>
      <el-table-column prop="userid" :label="$t('usertext')" sortable></el-table-column>
      <el-table-column prop="server" :label="$t('servertxt')" sortable></el-table-column>
      <el-table-column prop="strat_name" :label="$t('strategytxt')"></el-table-column>
      <el-table-column prop="old_location" :label="$t('locationold')" align="center" sortable></el-table-column>
      <el-table-column prop="new_location" :label="$t('locationnew')" align="center" sortable></el-table-column>
      <el-table-column prop="created_at" :label="$t('createdat')" align="center" sortable></el-table-column>
      <el-table-column :label="$t('optionstxt')" align="center">
        <template slot-scope="{row}">
          <el-tooltip :content="`${$t('copy_pass')}`" placement="top">
            <p-button type="success" link @click="$copyToClipboard(row.password)">
              <i class="fa fa-key" style="font-size: x-large;"></i>
            </p-button>
          </el-tooltip>
          <el-tooltip :content="`${$t('copy_data')}`" placement="top">
            <p-button
              type="warning"
              link @click="$copyToClipboard(`${row.strat_master_account} ${row.strat_master_server} ${row.strat_master_password}`)">
              <i class="fa fa-star" style="font-size: x-large;"></i>
            </p-button>
          </el-tooltip>
          <el-tooltip :content="`${$t('remove_row')}`" placement="top">
            <p-button type="danger" link @click="del(row.id)">
              <i class="fa fa-trash" style="font-size: x-large;"></i>
            </p-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </template>
    <template slot="filter-field">
      <fg-input v-model="filters.account" :label="$t('account')"></fg-input>
      <fg-input v-model="filters.userid" :label="$t('usertext')"></fg-input>
      <fg-input v-model="filters.broker" :label="$t('brokertxt')"></fg-input>
      <fg-input v-model="filters.strat_name" :label="$t('strategytxt')"></fg-input>
      <fg-input v-model="filters.old_location" :label="$t('locationold')"></fg-input>
      <fg-input v-model="filters.new_location" :label="$t('locationnew')"></fg-input>
      <fg-input :label="$t('createdat')">
        <date-picker
          v-model="filters.created_at"
          dateFormat="YYYY-MM-DD"
        ></date-picker>
      </fg-input>
    </template>
  </module>
</template>

<script>
import DatePicker from "src/components/UIComponents/Inputs/DatePicker";
import swal from "sweetalert2";
import {Tooltip} from 'element-ui'

export default {
  name: 'MatRelocation',
  components: {
    DatePicker,
    [Tooltip.name]: Tooltip
  },
  data() {
    return {
      filters: {},
    }
  },
  methods: {
    del(id) {
      swal(this.$t('attentiontxt'), this.$t('areyousurestatement'), 'warning')
        .then(() => {
          this.$refs.module.inProgress = true
          this.$delMatRelocationChangelog({id:id})
          .then(
            resp => {
              this.$toast.success(this.$t(resp.msg));
              this.$refs.module.refreshList();
              this.$refs.module.inProgress = false
            },
            error => this.$toast.warning(this.$t(error.data.msg))
          );
        })
        .catch(err => {
            this.$toast.warning(this.$t('statementdeletefailed'));
        });
    }
  },
}
</script>
<style scoped lang="scss">

</style>
